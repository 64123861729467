<template>
  <main class="sale-course-page" v-if="clubVersion === 'v2'">
    <section class="sale-course-page-content">
      <h1 class="title">Compre agora 🔥</h1>
      <section class="body-content">
        <video
          v-show="src"
          ref="videoPlayer"
          class="video-js"
          :controls="true"
          :autoplay="true"
        >
          <source :src="src" :type="type" />
        </video>
        <h2 class="course-name" v-if="dadosCurse.title">
          {{ dadosCurse.title }}
        </h2>
        <p
          class="description"
          v-if="descriptionBuy"
          v-html="descriptionBuy"
        ></p>
        <button class="buy-button" @click="toCheckout">
          {{ textButton }}
        </button>
      </section>
    </section>
  </main>
  <div class="w100" v-else>
    <div class="center">
      <div class="allContentModal">
        <div class="spaceText1">
          <p class="text1">{{ dadosCurse.title }}</p>
        </div>
        <div class="animationOpacity2" v-if="src !== null">
          <b-form-group label="" label-for="descricao" class="labelvideo">
            <video
              ref="videoPlayer"
              class="video-js widthVideoPlayer"
              :tech-order="techOrder"
              :autoplay="autoplay"
              :controls="true"
              v-on:pause="onVideoPause"
              @changed="playerStateChanged($event)"
            >
              <source :src="src" :type="type" />
            </video>
          </b-form-group>
        </div>
        <div class="spaceText2">
          <span class="text2" v-html="descriptionBuy"></span>
        </div>
        <div>
          <button class="button" @click="toCheckout">{{ textButton }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "video.js/dist/video-js.css";
import videojs from "video.js";
import "@devmobiliza/videojs-vimeo/dist/videojs-vimeo.esm";
import "videojs-youtube";
import CourseService from "@/services/resources/CourseService";
const serviceCourse = CourseService.build();

export default {
  data() {
    return {
      dadosCurse: {},
      autoplay: true,
      player: null,
      show_player: false,
      techOrder: "youtube",
      type: "video/youtube",
      src: null,
      linkCheckout: "",
      descriptionBuy: "",
      textButton: "Quero Aproveitar Agora",
    };
  },
  computed: {
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    clubVersion() {
      return this.$store.state.globalSettings.clubVersion;
    },
  },
  methods: {
    getCourse(id) {
      this.$root.$emit("loadOn");
      serviceCourse
        .read(id)
        .then((resp) => {
          this.getMetaCourse(id);
          //console.log("get course", resp);
          this.dadosCurse = resp;
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    toCheckout() {
      window.open(this.linkCheckout);
    },
    playerDispose() {
      this.player.dispose();
    },
    playerGetTime() {
      return this.player.currentTime();
    },
    getPlayer() {
      this.player = videojs(this.$refs.videoPlayer, {}, function onPlayerReady() {
      });
      this.show_player = true;
      this.$forceUpdate();
    },
    onVideoPause: function () {
      //console.log("video is paused");
    },
    play() {
      this.player.play();
    },
    pause() {
      this.player.pause();
    },
    playerStateChanged: function (playerCurrentState) {
      //console.log("player current update state", playerCurrentState);
    },
    getMetaCourse(id) {
      this.src = null;

      let url =
        id +
        "/meta?keys[]=sale_link&keys[]=sale_video&keys[]=sale_video_thumb&keys[]=sale_button_txt&keys[]=sale_description&keys[]=sale_video_type";
      serviceCourse
        .read(url)
        .then((resp) => {
          //console.log("meta sale modal", resp);
          this.linkCheckout = resp.sale_link;
          if (resp.sale_video_type === "vimeo") {
            this.type = "video/vimeo";
          }
          this.descriptionBuy = resp.sale_description;
          if (resp.sale_button_txt !== null) {
            this.textButton = resp.sale_button_txt;
          }
          if (resp.sale_video) {
            this.src = resp.sale_video;
            setTimeout(() => {
              this.getPlayer();
            }, 1000);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
  mounted() {
    this.getCourse(parseInt(this.$route.params.id));
  },
};
</script>
<style lang="scss">
.center {
  display: flex;
  justify-content: center;
}
header {
  border: none;
}
label {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--fontcolor2);
}
.video-js {
  height: 47vh;
}
.labelvideo {
  label {
    display: flex !important;
  }
}
.allContentModal {
  padding: 0px 10px;
  margin: 0 auto;
  display: block;
  text-align: -webkit-center;
}
.spaceText2 {
  margin-bottom: 20px;
  margin-top: 20px;
}
.spaceText1 {
  margin-bottom: 20px;
}
.text1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.2px;
  color: var(--fontcolor);
  text-align: center;
}
.text2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #818181;
}
.button {
  background: #000000;
  width: auto;
  padding: 0px 15px;
  height: 55px;
  border-radius: 3px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #ffffff;
  border: none;
}

.sale-course-page {
  padding-top: 65px;
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &-content {
    width: 100%;
    max-width: 475px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: hidden;
  }

  .body-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.default-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--text-color);
}
.title {
  @extend .default-text;
  margin-bottom: 30px;
}
.video-js {
  border-radius: 10px;
  display: flex;
  align-self: center;
  width: 100%;
}

.course-name {
  @extend .default-text;
  margin-top: 10px;
}
.description {
  @extend .default-text;
  font-size: 15px;
  font-weight: 400;
  * {
    max-height: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.buy-button {
  @extend .default-text;
  font-weight: 700;
  font-size: 14px;
  color: #16181A;

  outline: none;
  border: none;
  background: var(--maincolor);
  border-radius: 50px;
  padding: 15px 0;
  transition: ease-in-out 200ms;

  &:hover {
    transform: scale3d(0.9, 0.9, 0.9);
  }
}
</style>
